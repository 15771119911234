export const component = async () => {
    const slick = await import('../vendor/jquery.slick');

    //setupGalleries is also called via an event in the view for page editor mode, so galleries render in page editor...
    function setupGalleries() {
        $('.image-gallery--standard').each(function (index) {
            if ($(this).find('.slick-list').length === 0) {
                const $gallery = $(this);
                const $nav = $gallery.next('.slider-nav');

                $gallery.attr('id', 'standard-image-gallery-' + index);
                $nav.attr('id', 'standard-image-gallery-nav-' + index);

                $gallery.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: '#standard-image-gallery-nav-' + index,
                });
                $nav.slick({
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    asNavFor: '#standard-image-gallery-' + index,
                    focusOnSelect: true,
                    infinite: true,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                arrows: false,
                                centerMode: false,
                                slidesToShow: 4,
                                infinite: true,
                            },
                        },
                    ],
                });

                //Trigger a refresh once if within an acccordion
                const $collapse = $(this).parents('.accordion');

                if ($collapse.length > 0) {
                    var hasRefreshed = false;

                    $collapse.find('a.accordion__header').on('click', function (ev) {
                        if (!hasRefreshed) {
                            $gallery.slick('refresh');
                            $nav.slick('refresh');
                            hasRefreshed = true;
                        }
                    });
                }
            }
        });
    }

    setupGalleries();

    var resizeWidthOnly = function (a, b) {
        var c = [window.innerWidth];
        return (
            (onresize = function () {
                var d = window.innerWidth,
                    e = c.length;
                c.push(d);
                if (c[e] !== c[e - 1]) {
                    clearTimeout(b);
                    b = setTimeout(a, 250);
                }
            }),
            a
        );
    };

    /*fix slick slider snap back on resize*/
    resizeWidthOnly(function () {
        var windowWidth = $(window).width();
        if (windowWidth >= 320) {
            $('.slider-nav > .slick-list > .slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
        }
    });

    //Handle sidebar expand
    window.addEventListener('sidebarToggled', function () {
        $('.slider-nav').slick('refresh');
        $('.image-gallery--standard').slick('refresh');
    });
    //For initialising in the CMS page editor after a drag and drop
    window.addEventListener('galleryAdded', function () {
        setupGalleries();

        // $('.image-gallery--standard').each(function() {
        //     if ($(this).find('.slick-list').length === 0) {
        //     }
        // });
    });
};
